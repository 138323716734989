import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Loading from '../Components/Loading/loading';
import routes from '../Modules/routes';
import {
  getUserAccountRequest,
  getUserAccountNotificationsRequest,
  getUserLocationRequest,
} from '../redux/account/actions';
import PermissionsModule from '../Modules/Permission';
import NewNavBar from '../Components/NavBar/newNavBar';
import { getAllCountriesRequest } from '../redux/country/actions';

class PrivateRoutes extends PureComponent {
  static propTypes = {
    handleLogout: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    handlePersonalDetailsOnBoarded: PropTypes.func.isRequired,
    isPersonalDetailsOnBoarded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    handleCompanyOnBoarded: PropTypes.func.isRequired,
    isCompanyOnBoarded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    handleIndividualUserOnBoardDone: PropTypes.func.isRequired,
    individualUserOnBoarded: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    handleEmailVerified: PropTypes.func.isRequired,
    isEmailVerified: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    handlePhoneVerified: PropTypes.func.isRequired,
    isPhoneVerified: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
    getAccount: PropTypes.func.isRequired,
    isGetUserAccountSuccess: PropTypes.bool.isRequired,
    isGetUserAccountError: PropTypes.bool.isRequired,
    userAccount: PropTypes.object.isRequired,
    getAccountNotifications: PropTypes.func.isRequired,
    getAllCountries: PropTypes.func.isRequired,
    accountNotifications: PropTypes.array.isRequired,
    notificationsCount: PropTypes.number.isRequired,
    isJustInvitationAccepted: PropTypes.bool.isRequired,
    getUserLocation: PropTypes.func.isRequired,
    isGetUserLocationSuccess: PropTypes.bool.isRequired,
    isGetUserLocationError: PropTypes.bool.isRequired,
    locationInfo: PropTypes.object.isRequired,
    isIndivid: PropTypes.bool,
  };

  static defaultProps = {
    isIndivid: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      emailVerified: localStorage.getItem('isEmailVerified'),
      loading: true,
      rolesPagesAccess: [],
    };
  }

  componentDidMount() {
    const {
      getAccount, isAuthenticated, getUserLocation,
    } = this.props;
    isAuthenticated ? getAccount() : this.setState({ loading: false });
    getUserLocation();
  }

  componentDidUpdate(prevProps) {
    const {
      getAccount,
      isGetUserAccountSuccess,
      isGetUserAccountError,
      userAccount,
      handleCompanyOnBoarded,
      handleEmailVerified,
      isEmailVerified,
      isAuthenticated,
      handlePersonalDetailsOnBoarded,
      handlePhoneVerified,
      handleIndividualUserOnBoardDone,
      getAccountNotifications,
      getAllCountries,
    } = this.props;
    if (!prevProps.isAuthenticated && isAuthenticated) {
      // If user is authenticated, get user account
      this.setState({ loading: true });
      getAccount();
    }
    if (!prevProps.isGetUserAccountError && isGetUserAccountError && !isGetUserAccountSuccess) {
      // If any error for getting user account, redirect to login
      localStorage.removeItem('token');
      window.location.reload(false);
    }
    if (!prevProps.isGetUserAccountSuccess && isGetUserAccountSuccess && !isGetUserAccountError && userAccount.id) {
      // Handle all on boardings and verifications after getting user account successfully
      handleCompanyOnBoarded(userAccount.company_is_on_boarded_at);
      handleEmailVerified(userAccount.email_verified_at);
      handlePersonalDetailsOnBoarded(userAccount.personal_details_is_on_boarded_at);
      handlePhoneVerified(userAccount.phone_number_verified_at);
      handleIndividualUserOnBoardDone(userAccount.individual_user_is_on_boarded_at);
      const emailVerified = localStorage.getItem('isEmailVerified');
      getAccountNotifications({
        page: 1,
      });
      getAllCountries();
      if (userAccount.email_verified_at && (userAccount.personal_details_is_on_boarded_at || userAccount.company_is_on_boarded_at)) {
        let rolesPagesAccess = PermissionsModule.getPagesAccess(userAccount.testPermissions);
        const roles = Object.keys(rolesPagesAccess);

        const rolesCopy = [...roles];
        // remove the first item from array
        rolesCopy.shift();

        const [first] = roles;
        // get first role pages access
        const firstRolePages = Object.keys(rolesPagesAccess).length ? [...rolesPagesAccess[first]] : [];
        const general = PermissionsModule.getGeneralRouteLinks(firstRolePages, rolesCopy, rolesPagesAccess);
        if (userAccount.activeRole && userAccount.activeRole.name) {
          localStorage.setItem('prefix', userAccount.activeRole.name);
        }

        // remove links from each role, if the link is in general
        rolesPagesAccess = PermissionsModule.removeDuplicateLinks(roles, rolesPagesAccess, general);

        this.setState({
          emailVerified,
          loading: false,
          rolesPagesAccess: {
            General: general,
            ...rolesPagesAccess,
          },
        });
      } else {
        this.setState({
          emailVerified,
          loading: false,
        });
      }
    }
    if ((!prevProps.isEmailVerified || !JSON.parse(prevProps.isEmailVerified)) && JSON.parse(isEmailVerified)) {
      // Set email verify or not
      this.setState({ loading: true });
      getAccount();
    }
  }

  componentWillUnmount() {
    if (this.socket) {
      this.socket.off();
    }
  }

  getRouteComponent = (route) => {
    const { rolesPagesAccess } = this.state;
    const { userAccount } = this.props;
    const customerPagePath = '/customers/:filter?';

    if (route.component) {
      return route.component;
    } else {
      const { name: roleName } = userAccount.activeRole;

      let componentRoleName = roleName;
      if (componentRoleName !== 'Owner') {
        const roleKeys = Object.keys(rolesPagesAccess);
        componentRoleName = roleKeys.find((key) => (
          rolesPagesAccess[key].includes(route.name)
        ));
        if (route.path === customerPagePath && roleKeys.includes('Staff') && roleKeys.includes('Individual')) {
          componentRoleName = 'General';
        }
      }

      let componentName = false;
      if (componentRoleName) {
        componentName = `${componentRoleName.toLowerCase()}Component`;
      }

      return componentName ? route[componentName] : false;
    }
  };

  isTrueSet = (val) => (val === true || val === 'true');

  renderRouteComponent = (props, RouteVal, emailVerified, isPersonalDetailsOnBoarded, userAccount, locationInfo, notifications, notificationsCount) => {
    const {
      handleLogout,
      handleEmailVerified,
    } = this.props;

    return (
      <NewNavBar
        account={userAccount}
        userLocationInfo={locationInfo}
        notifications={notifications}
        notificationsCount={notificationsCount}
        handleLogout={handleLogout}
      >
        <RouteVal
          {...props}
          handleEmailVerified={(emailVerified) => handleEmailVerified(emailVerified)}
        />
      </NewNavBar>
    );
  };

  getPrivateRoutes = (emailVerified, isPersonalDetailsOnBoarded, isCompanyOnBoarded) => {
    const { isIndivid } = this.props;
    let privateRoutes = routes.filter((route) => route.name === 'staff' && isIndivid ? false : (route.isPrivate || route.isPublic));
    if (!this.isTrueSet(emailVerified)) {
      privateRoutes = [
        ...privateRoutes.filter((route) => (route.path === '/email/not-verified' || route.isPublic)),
        {
          redirect: true,
          path: '/email/not-verified',
        },
      ];
    } else if (!this.isTrueSet(isPersonalDetailsOnBoarded || isCompanyOnBoarded)) {
      privateRoutes = [
        ...privateRoutes.filter((route) => (route.path === '/onboarding' || route.isPublic)),
        {
          redirect: true,
          path: '/onboarding',
        },
      ];
    } else {
      privateRoutes = [
        ...privateRoutes.filter((route) => (route.path !== '/email/not-verified' && route.path !== '/onboarding')),
        {
          redirect: true,
          path: '/calendar',
        },
      ];
    }

    return privateRoutes;
  };

  getRoutesToRender = () => {
    const { emailVerified } = this.state;
    const {
      isAuthenticated,
      userAccount,
      locationInfo,
      isPersonalDetailsOnBoarded,
      isCompanyOnBoarded,
      handleEmailVerified,
      isJustInvitationAccepted,
      accountNotifications,
      notificationsCount,
    } = this.props;

    const userRegisterFinished = userAccount && emailVerified && (isPersonalDetailsOnBoarded || isCompanyOnBoarded);
    const privateRoutes = this.getPrivateRoutes(emailVerified, isPersonalDetailsOnBoarded, isCompanyOnBoarded);

    return privateRoutes.map((route, index) => {
      let RouteVal = null;
      if (route.redirect) {
        return (
          <Route path="*" key={`${route.path}-${index}`}>
            <Redirect to={route.path} />
          </Route>
        );
      } else if (route.isPublic || (!userRegisterFinished)) {
        const RouteVal = route.component;
        return (
          <Route
            key={route.id}
            path={route.path}
            exact
            render={(props) => (
              <RouteVal
                {...props}
                handleEmailVerified={(emailVerified) => handleEmailVerified(emailVerified)}
                userAccount={userAccount}
                notifications={accountNotifications}
                notificationsCount={notificationsCount}
                isJustInvitationAccepted={isJustInvitationAccepted}
              />
            )}
          />
        );
      } else if (
        isAuthenticated
        && userAccount
        && userAccount.activeRole
        && userAccount.activeRole.name
      ) {
        RouteVal = this.getRouteComponent(route);
        if (RouteVal) {
          return (
            <Route
              key={route.id}
              path={route.path}
              exact
              render={(props) => this.renderRouteComponent(props, RouteVal, emailVerified, isPersonalDetailsOnBoarded, userAccount, locationInfo, accountNotifications, notificationsCount, isCompanyOnBoarded)}
            />
          );
        }
      }
      return null;
    });
  };

  render() {
    const { loading } = this.state;

    return !loading ? (
      <Switch>{this.getRoutesToRender()}</Switch>
    ) : (<Loading />);
  }
}

const mapStateToProps = (state) => ({
  // Get Account
  userAccount: state.account.userAccount,
  isGetUserAccountSuccess: state.account.isGetUserAccountSuccess,
  isGetUserAccountError: state.account.isGetUserAccountError,
  // Get Account Notifications
  accountNotifications: state.account.accountNotifications,
  notificationsCount: state.account.notificationsCount,
  // Get Account location
  locationInfo: state.account.locationInfo,
  isGetUserLocationSuccess: state.account.isGetUserLocationSuccess,
  isGetUserLocationError: state.account.isGetUserLocationError,
  isIndivid: !state.company.companyGot.is_staff_based,
});

function mapDispatchToProps(dispatch) {
  return {
    getAccount: (data) => dispatch(getUserAccountRequest(data)),
    getAccountNotifications: (data) => dispatch(getUserAccountNotificationsRequest(data)),
    getAllCountries: () => dispatch(getAllCountriesRequest()),
    getUserLocation: () => dispatch(getUserLocationRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoutes);
