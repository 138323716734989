import { handleActions } from 'redux-actions';
import {
  loginRequest,
  loginSuccess,
  loginError,
  sendEmailRequest,
  sendEmailSuccess,
  sendEmailError,
  checkTokenRequest,
  checkTokenSuccess,
  checkTokenError,
  registerRequest,
  registerSuccess,
  registerError,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  loginWithGoogleRequest,
  loginWithGoogleSuccess,
  loginWithGoogleError,
  loginWithFacebookRequest,
  loginWithFacebookSuccess,
  loginWithFacebookError,
  confirmVerificationCodeRequest,
  confirmVerificationCodeSuccess,
  confirmVerificationCodeError,
  resendVerificationCodeRequest,
  resendVerificationCodeSuccess,
  resendVerificationCodeError,
} from './actions';

const initialState = {
  accessToken: 'null',
  isLoginSuccess: false,
  isFetching: false,
  isLoginError: false,
  errorMessage: null,
  isSendEmailSuccess: false,
  isSendEmailError: false,
  sendEmailErrors: {},
  isCheckTokenSuccess: false,
  isCheckTokenError: false,
  isRegisterSuccess: false,
  isRegisterError: false,
  registerErrors: {},
  registerErrorMessage: '',
  isForgotPasswordSuccess: false,
  isForgotPasswordError: false,
  forgotPasswordErrorMessage: '',
  forgotPasswordEmail: '',
  isConfirmVerificationCodeSuccess: false,
  isConfirmVerificationCodeError: false,
  confirmVerificationCodeErrorMessage: '',
  verificationCodeData: {},
  isResetPasswordSuccess: false,
  isResetPasswordError: false,
  resetPasswordErrors: {},
  userAccount: {},
  forgotPasswordErrors: {},
  isLoginWithGoogleSuccess: false,
  isLoginWithGoogleError: false,
  isLoginWithFacebookSuccess: false,
  isLoginWithFacebookError: false,
  isResendVerificationCodeSuccess: false,
  isResendVerificationCodeError: false,
  resendVerificationCodeErrorMessage: '',
};

const reducer = handleActions(
  {
    [loginRequest]: (state) => ({
      ...state,
      isLoginSuccess: false,
      isLoginError: false,
      errorMessage: null,
    }),
    [loginSuccess]: (state, { payload }) => ({
      ...state,
      accessToken: payload.data.token,
      userAccount: payload.data.account,
      isLoginSuccess: true,
    }),
    [loginError]: (state, { payload }) => ({
      ...state,
      isLoginError: true,
      errorMessage: payload.message,
    }),
    [sendEmailRequest]: (state) => ({
      ...state,
      isSendEmailSuccess: false,
      isSendEmailError: false,
      sendEmailErrors: {},
    }),
    [sendEmailSuccess]: (state) => ({
      ...state,
      isSendEmailSuccess: true,
      sendEmailErrors: {},
    }),
    [sendEmailError]: (state, { payload }) => ({
      ...state,
      isSendEmailError: true,
      sendEmailErrors: payload.errors || {},
    }),
    [checkTokenRequest]: (state) => ({
      ...state,
      isCheckTokenSuccess: false,
      isCheckTokenError: false,
    }),
    [checkTokenSuccess]: (state) => ({
      ...state,
      isCheckTokenSuccess: true,
    }),
    [checkTokenError]: (state) => ({
      ...state,
      isCheckTokenError: true,
    }),
    [registerRequest]: (state) => ({
      ...state,
      isRegisterSuccess: false,
      isRegisterError: false,
      registerErrorMessage: '',
      registerErrors: {},
    }),
    [registerSuccess]: (state, { payload }) => ({
      ...state,
      isRegisterSuccess: true,
      userAccount: payload.data,
      registerErrorMessage: '',
      registerErrors: {},
    }),
    [registerError]: (state, { payload }) => ({
      ...state,
      isRegisterError: true,
      registerErrors: payload.errors || {},
      registerErrorMessage: payload.message || '',
    }),
    [forgotPasswordRequest]: (state) => ({
      ...state,
      isForgotPasswordSuccess: false,
      isForgotPasswordError: false,
    }),
    [forgotPasswordSuccess]: (state, { payload }) => ({
      ...state,
      isForgotPasswordSuccess: true,
      forgotPasswordEmail: payload,
    }),
    [forgotPasswordError]: (state, { payload }) => ({
      ...state,
      isForgotPasswordError: true,
      forgotPasswordErrors: payload.message || payload.errors.email,
    }),
    [confirmVerificationCodeRequest]: (state) => ({
      ...state,
      isConfirmVerificationCodeSuccess: false,
      isConfirmVerificationCodeError: false,
    }),
    [confirmVerificationCodeSuccess]: (state, { payload }) => ({
      ...state,
      isConfirmVerificationCodeSuccess: true,
      verificationCodeData: payload.data,
    }),
    [confirmVerificationCodeError]: (state, { payload }) => ({
      ...state,
      isConfirmVerificationCodeError: true,
      confirmVerificationCodeErrorMessage: payload.message || payload.errors.code,
    }),
    [resetPasswordRequest]: (state) => ({
      ...state,
      resetPasswordSuccessData: undefined,
      resetPasswordErrors: undefined,
    }),
    [resetPasswordSuccess]: (state, { payload }) => ({
      ...state,
      resetPasswordSuccessData: payload.data,
      resetPasswordErrors: undefined,
    }),
    [resetPasswordError]: (state, { payload }) => ({
        ...state,
        resetPasswordSuccessData: undefined,
        resetPasswordErrors: payload.message,
    }),
    [loginWithGoogleRequest]: (state) => ({
      ...state,
      isLoginWithGoogleSuccess: false,
      isLoginWithGoogleError: false,
    }),
    [loginWithGoogleSuccess]: (state, { payload }) => ({
      ...state,
      accessToken: payload.data.token,
      userAccount: payload.data.account,
      isLoginWithGoogleSuccess: true,
    }),
    [loginWithGoogleError]: (state) => ({
      ...state,
      isLoginWithGoogleError: true,
    }),
    [loginWithFacebookRequest]: (state) => ({
      ...state,
      isLoginWithFacebookSuccess: false,
      isLoginWithFacebookError: false,
    }),
    [loginWithFacebookSuccess]: (state, { payload }) => ({
      ...state,
      accessToken: payload.data.token,
      userAccount: payload.data.account,
      isLoginWithFacebookSuccess: true,
    }),
    [loginWithFacebookError]: (state) => ({
      ...state,
      isLoginWithFacebookError: true,
    }),
    [resendVerificationCodeRequest]: (state) => ({
      ...state,
      isResendVerificationCodeSuccess: false,
      isResendVerificationCodeError: false,
      resendVerificationCodeErrorMessage: '',
    }),
    [resendVerificationCodeSuccess]: (state) => ({
      ...state,
      isResendVerificationCodeSuccess: true,
      isResendVerificationCodeError: false,
      resendVerificationCodeErrorMessage: '',
    }),
    [resendVerificationCodeError]: (state, { payload }) => ({
      ...state,
      isResendVerificationCodeSuccess: false,
      isResendVerificationCodeError: true,
      resendVerificationCodeErrorMessage: payload.message,
    }),
  },
  initialState,
);

export default reducer;
