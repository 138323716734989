import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import BusinessDetails from './BusinessDetails';
import { getAllCountriesRequest } from '../../../redux/country/actions';

import '../../Public/styles.scss';

function Onboarding() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { userAccount } = useSelector((state) => state.account);
  const { isGetAllCountriesSuccess, allCountries } = useSelector((state) => state.country);

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (Object.keys(userAccount).length === 0) {
      history.push('/login');
      return;
    }

    dispatch(getAllCountriesRequest());
  }, []);

  useEffect(() => {
    if (isGetAllCountriesSuccess) {
      setCountries(allCountries);
    }
  }, [isGetAllCountriesSuccess]);

  useEffect(() => {
    if (!userAccount.password && location.state) {
      userAccount.password = location.state.password ?? '';
      userAccount.first_name = (location.state.firstName ?? '').trim();
      userAccount.last_name = (location.state.lastName ?? '').trim();
    }
  }, [userAccount]);

  return <BusinessDetails {...{ userAccount, countries }} />;
}

export default Onboarding;
