import React from 'react';
import EmailVerifyV2 from '../EmailVerify/emailVerifyV2';
import Layout from '../Layout';

const VerificationCode = () => (
  <Layout>
    <EmailVerifyV2 isForgotPassword />
  </Layout>
);

export default VerificationCode;
