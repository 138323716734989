import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  InputLabel,
  OutlinedInput,
  TextField,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Grid,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ErrorIcon from '@material-ui/icons/Error';

import { loginRequest } from 'redux/auth/actions';
import Layout from '../Layout';
import OAuth from '../../../Components/Account/oAuth';

import '../styles.scss';
import { emailRegex } from '../../../Modules/regexValidations';

const Login = ({
  handleEmailVerified,
  handleLogin,
  handleCompanyOnBoarded,
  match,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isLoginSuccess,
    isLoginError,
    errorMessage,
    accessToken,
    userAccount,
  } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [changedField, setChangedField] = useState('');
  const [data, setData] = useState({ email: '', password: '' });
  const [formErrors, setFormErrors] = useState({ email: false, passwordMinLength: false });
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectLogin, setIncorrectLogin] = useState(false);

  useEffect(() => {
    if (isLoginSuccess) {
      localStorage.setItem('menuOpen', true);
      const { redirect } = match.params;
      handleLogin(accessToken);
      handleCompanyOnBoarded(userAccount.company_is_on_boarded_at);
      handleEmailVerified(userAccount.email_verified_at);

      setLoading(false);
      setIncorrectLogin(false);
      if (redirect && redirect === 'settings') {
        history.push('/my-account/settings');
      } else {
        history.push('/calendar');
      }
    } else if (isLoginError) {
      localStorage.removeItem('token');
      setLoading(false);
      setIncorrectLogin(isLoginError);
    }
  }, [isLoginSuccess, isLoginError]);

  useEffect(() => {
    if (changedField !== '') {
      isFormInvalid(changedField);
    }
  }, [changedField]);

  const isFormInvalid = (name) => {
    if (name) {
      name = name.trim();
    }

    const formErrorsCopy = { ...formErrors };

    switch (name) {
      case 'email':
        formErrorsCopy.validEmail = !emailRegex.test(data.email);
        break;
      case 'password':
        formErrorsCopy.passwordMinLength = !(data.password.length > 0);
        break;
      default:
        formErrorsCopy.validEmail = !emailRegex.test(data.email);
        formErrorsCopy.passwordMinLength = !(data.password.length > 0);
        break;
    }
    setFormErrors(formErrorsCopy);
    return Object.values(formErrorsCopy).some((val) => val === true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormInvalid()) {
      setLoading(true);
      dispatch(loginRequest(data));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });
    setChangedField((c) => c === name ? ` ${name} ` : name);

    setIncorrectLogin(false);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <Box className="form-container">
        <Box className="form-box">
          <Box p="50px">
            <p className="title">{t('SignIn')}</p>
            <p className="sub-title">{t('EnterSignInDetailsText')}</p>
            <form action="" method="" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!formErrors.email || !!formErrors.validEmail || incorrectLogin}
                  >
                    <TextField
                      error={!!formErrors.email || !!formErrors.validEmail || incorrectLogin}
                      fullWidth
                      label={t('Email')}
                      variant="outlined"
                      onChange={handleChange}
                      data-cy="email"
                      type="email"
                      value={data.email}
                      name="email"
                      size="small"
                    />

                    {formErrors.validEmail && !formErrors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>{t('EmailValid')}</FormHelperText>
                      </div>
                    )}

                    {formErrors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>{t('EmailRequired')}</FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!formErrors.password || !!formErrors.passwordLength || incorrectLogin}
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      error={!!formErrors.password || !!formErrors.passwordLength || incorrectLogin}
                    >
                      {t('Password')}
                    </InputLabel>
                    <OutlinedInput
                      error={!!formErrors.password || !!formErrors.passwordLength || incorrectLogin}
                      type={showPassword ? 'text' : 'password'}
                      id="icon-password"
                      name="password"
                      label={t('Password')}
                      data-cy="password"
                      onChange={handleChange}
                      endAdornment={(
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword()}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <Visibility style={{ color: '#393939' }} fontSize="small" />
                            ) : (
                              <VisibilityOff style={{ color: '#393939' }} fontSize="small" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      )}
                    />
                    {incorrectLogin ? (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>{errorMessage}</FormHelperText>
                      </div>
                    ) : formErrors.passwordMinLength ? (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>{t('PasswordRequired')}</FormHelperText>
                      </div>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Box className="forgot-password">
                <Link to="/forgot-password">
                  <span>{t('ForgotPassword1')}</span>
                </Link>
              </Box>
              <Box className="sign-in-btn">
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  data-cy="sign-in"
                  type="submit"
                  loading={loading}
                  fullWidth
                >
                  {loading && <CircularProgress color="white" size={20} />}
                  {!loading && t('SignIn')}
                </Button>
                <OAuth handleLogin={handleLogin} />
                <Box mt={3} display="flex" justifyContent="center" alignItems="center">
                  <Typography align="center" color="secondary">
                    {t('DontHaveAnAccount')}
                  </Typography>
                  <Box ml="5px">
                    <Link to="/create-account">
                      <Typography component="span" color="primary">
                        {t('signUp')}
                      </Typography>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

Login.propTypes = {
  match: PropTypes.object.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleCompanyOnBoarded: PropTypes.func.isRequired,
  handleEmailVerified: PropTypes.func.isRequired,
};

export default Login;
