import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRequest } from 'redux/auth/actions';
import {
  InputLabel,
  OutlinedInput,
  FormControl,
  Box,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  CircularProgress,
  makeStyles,
  FormHelperText,
} from '@material-ui/core';
import i18n from 'i18next';
import ErrorIcon from '@material-ui/icons/Error';
import Layout from '../Layout';

const useStyles = makeStyles(() => ({
  root: {
    '&.Mui-error': {
      borderColor: 'green',
    },
    '&.MuiFormHelperText-root': {
      color: 'yellow',
    },
  },
}));

const initialFormErrors = {
  passwordRequired: false,
  passwordLengthError: false,
  passwordCapitalError: false,
  passwordLowercaseError: false,
  passwordSpecialCharError: false,

  confirmPasswordRequired: false,
  confirmPasswordMismatch: false,
};

const preventDefault = (e) => e.preventDefault();

const getHelperTextColor = (error, password) => error || password === '' ? '#FF2A2A' : '#32CD32';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const {
    resetPasswordSuccessData,
    resetPasswordErrors,
    verificationCodeData,
  } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);
  const [changedField, setChangedField] = useState('');
  const [formErrors, setFormErrors] = useState(initialFormErrors);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const resetPasswordData = JSON.parse(sessionStorage.getItem('forgotPasswordEmail'));

  useEffect(() => {
    if (!verificationCodeData.token) {
      history.push('/login');
    }

    sessionStorage.setItem('forgotPasswordEmail', JSON.stringify({
      ...(JSON.parse(sessionStorage.getItem('forgotPasswordEmail'))),
      token: verificationCodeData.token,
    }));
  }, []);

  useEffect(() => {
    if (changedField) {
      isFormInvalid(changedField);
    }
  }, [changedField]);

  useEffect(() => {
    if (resetPasswordSuccessData) {
      history.push('/login');
      sessionStorage.removeItem('forgotPasswordEmail');
    }

    setLoading(false);
  }, [resetPasswordSuccessData]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormInvalid()) {
      dispatch(resetPasswordRequest({
        token: resetPasswordData.token,
        verification_code: resetPasswordData.verificationCode,
        password: newPassword,
        password_confirmation: confirmPassword,
        lang: i18n.language,
      }));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'newPassword': {
        setNewPassword(value);
        break;
      } case 'confirmPassword': {
        setConfirmPassword(value);
        break;
      }
    }

    setChangedField((c) => c === name ? ` ${name} ` : name);
  };

  const handleClickShowPassword = (type) => {
    if (type === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const isFormInvalid = (field) => {
    if (field) {
      field = field.trim();
    }

    const formErrorsCopy = { ...formErrors };

    const passwordError = !(newPassword.length > 0);
    const passwordLengthError = !(newPassword.length >= 8);
    const passwordCapitalError = !newPassword.match(/(?=.*[A-Z])/);
    const passwordLowercaseError = !newPassword.match(/(?=.*[a-z])/);
    const passwordSpecialCharError = !newPassword.match(/^(?=.*[0-9_\W]).+$/);

    const confirmPasswordRequired = !(confirmPassword.length > 0);
    const confirmPasswordMismatch = !(confirmPassword === newPassword);

    switch (field) {
      case 'newPassword': {
        formErrorsCopy.passwordRequired = passwordError;
        formErrorsCopy.passwordLengthError = passwordLengthError;
        formErrorsCopy.passwordCapitalError = passwordCapitalError;
        formErrorsCopy.passwordLowercaseError = passwordLowercaseError;
        formErrorsCopy.passwordSpecialCharError = passwordSpecialCharError;
        break;
      } case 'confirmPassword': {
        formErrorsCopy.confirmPasswordRequired = confirmPasswordRequired;
        formErrorsCopy.confirmPasswordMismatch = confirmPasswordMismatch;
        break;
      } default: {
        formErrorsCopy.passwordRequired = passwordError;
        formErrorsCopy.passwordLengthError = passwordLengthError;
        formErrorsCopy.passwordCapitalError = passwordCapitalError;
        formErrorsCopy.confirmPasswordRequired = confirmPasswordRequired;
        formErrorsCopy.confirmPasswordMismatch = confirmPasswordMismatch;
        formErrorsCopy.passwordLowercaseError = passwordLowercaseError;
        formErrorsCopy.passwordSpecialCharError = passwordSpecialCharError;
        break;
      }
    }

    setFormErrors(formErrorsCopy);
    return Object.values(formErrorsCopy).some((error) => error === true);
  };

  return (
    <>
      <Layout>
        <Box className="form-container">
          <Box className="form-box">
            <Box p="50px">
              <p className="title">{i18n.t('ResetPassword')}</p>
              <p className="sub-title">{i18n.t('NewPassMustBeDifferentText')}</p>
              <form action="" method="" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} className="reset-pass-email-input">
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="outlined-adornment-password">{i18n.t('NewPassword')}</InputLabel>
                      <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        id="icon-eye"
                        name="newPassword"
                        label={i18n.t('NewPassword')}
                        value={newPassword}
                        onChange={handleChange}
                        className={classes.root}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('password')}
                              onMouseDown={preventDefault}
                              edge="end"
                            />
                          </InputAdornment>
                        )}
                        error={
                          formErrors.passwordLengthError
                          || formErrors.passwordRequired
                          || formErrors.passwordCapitalError
                          || formErrors.passwordLowercaseError
                          || formErrors.passwordSpecialCharError
                        }
                      />
                      {formErrors.passwordRequired && (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>{i18n.t('PasswordRequired')}</FormHelperText>
                        </div>
                      )}

                      {formErrors.passwordLengthError && !formErrors.passwordRequired && (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>{i18n.t('PasswordLengthRule')}</FormHelperText>
                        </div>
                      )}

                      {formErrors.passwordCapitalError
                        && !formErrors.passwordRequired
                        && !formErrors.passwordLengthError ? (
                          <div className="error-message-content">
                            <ErrorIcon fontSize="small" color="error" />
                            <FormHelperText>{i18n.t('PasswordCapitalRule')}</FormHelperText>
                          </div>
                        ) : null}

                      <ul style={{ margin: 0 }} className="helper-text show">
                        <li style={{ color: getHelperTextColor(formErrors.passwordRequired, newPassword) }}>
                          {i18n.t('PasswordMinLength')}
                        </li>
                        <li style={{ color: getHelperTextColor(formErrors.passwordLowercaseError, newPassword) }}>
                          {i18n.t('PasswordLowercase')}
                        </li>
                        <li style={{ color: getHelperTextColor(formErrors.passwordCapitalError, newPassword) }}>
                          {i18n.t('PasswordUppercase')}
                        </li>
                        <li style={{ color: getHelperTextColor(formErrors.passwordSpecialCharError, newPassword) }}>
                          {i18n.t('PasswordSpecial')}
                        </li>
                      </ul>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="outlined-adornment-password">{i18n.t('ConfirmPassword')}</InputLabel>
                      <OutlinedInput
                        error={formErrors.confirmPasswordRequired || formErrors.confirmPasswordMismatch}
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="eye-icon"
                        name="confirmPassword"
                        label={i18n.t('ConfirmPassword')}
                        value={confirmPassword}
                        onChange={handleChange}
                        className={classes.root}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('confirm')}
                              onMouseDown={preventDefault}
                              edge="end"
                            />
                          </InputAdornment>
                        )}
                      />

                      {formErrors.confirmPasswordRequired && (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>{i18n.t('ConfirmPassword')}</FormHelperText>
                        </div>
                      )}

                      {formErrors.confirmPasswordMismatch && (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>{i18n.t('PasswordMismatch')}</FormHelperText>
                        </div>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Box mt={2} className="sign-in-btn">
                  {typeof resetPasswordErrors === 'string' ? (
                    <div style={{ justifyContent: 'center' }} className="error-message-content">
                      <FormHelperText>{resetPasswordErrors}</FormHelperText>
                    </div>
                  ) : null}

                  <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    data-cy="sign-in"
                    type="submit"
                    loading={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress color="white" size={20} />}
                    {!loading && i18n.t('Confirm')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ResetPassword;
