import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordRequest } from 'redux/auth/actions';
import {
  TextField,
  FormControl,
  FormHelperText,
  Box,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { useTranslation } from 'react-i18next';
import Layout from '../Layout';
import { emailRegex } from '../../../Modules/regexValidations';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    isForgotPasswordSuccess,
    isForgotPasswordError,
    forgotPasswordErrors,
    forgotPasswordEmail,
  } = useSelector((state) => state.auth);

  const [email, setEmail] = useState(undefined);
  const [formErrors, setFormErrors] = useState({ email: { isInvalid: false } });
  const [emailError, setEmailError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isForgotPasswordSuccess) {
      setLoading(false);
      sessionStorage.setItem('forgotPasswordEmail', JSON.stringify({ forgotPasswordEmail }));
      history.push('/verification-code');
    } else if (isForgotPasswordError) {
      setEmailError(forgotPasswordErrors);
      setLoading(false);
    }
  }, [isForgotPasswordSuccess, isForgotPasswordError]);

  useEffect(() => {
    if (email !== undefined) {
      isFormInvalid();
    }
  }, [email]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormInvalid()) {
      setLoading(true);
      dispatch(forgotPasswordRequest({ email }));
    }
  };

  const isFormInvalid = () => {
    const formErrorsCopy = { ...formErrors };

    formErrorsCopy.email.isInvalid = !emailRegex.test(email.trim());

    setFormErrors(formErrorsCopy);

    return formErrorsCopy.email.isValid;
  };

  return (
    <>
      <Layout>
        <Box className="form-container">
          <Box className="form-box">
            <Box p="50px">
              <p className="title">
                {t('ForgotPassword2')}
              </p>
              <p className="sub-title">
                {t('WeWillSendVerificationCode')}
              </p>
              <form action="" method="" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} style={{ marginBottom: '16px' }} className="forgot-pass-email-input">
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      error={!!formErrors.email.isInvalid || !!emailError}
                    >
                      <TextField
                        error={!!formErrors.email.isInvalid || !!emailError}
                        fullWidth
                        id="outlined-basic"
                        size="small"
                        data-cy="email"
                        type="email"
                        label={t('Email')}
                        variant="outlined"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      { emailError ? (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>
                            {emailError}
                          </FormHelperText>
                        </div>
                      ) : formErrors.email.isInvalid ? (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>
                            {t('EmailValid')}
                          </FormHelperText>
                        </div>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
                <Box className="sign-in-btn">
                  <Button
                    disabled={loading || !email || formErrors.email.isInvalid}
                    variant="contained"
                    color="primary"
                    data-cy="sign-in"
                    type="submit"
                    loading={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress color="white" size={20} />}
                    {!loading && t('Next')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ForgotPassword;
