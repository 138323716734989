import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { verifyEmailRequest } from '../../../redux/account/actions';
import {
  confirmVerificationCodeRequest,
  resendVerificationCodeRequest,
  sendEmailRequest,
} from '../../../redux/auth/actions';

import Logo from '../../../assets/img/logo.svg';
import '../styles.scss';

const inputDigits = ['', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

const timerConfig = { duration: 80, timeout: 1000 };

const invalidCodeTimeout = 5000;

const validCodeLength = 6;

const formatTime = (time) => `${String(Math.floor(time / 60)).padStart(2, '0')}:${String(
  time % 60,
).padStart(2, '0')}`;

function EmailVerifyV2({ isForgotPassword = false }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isVerifyEmailSuccess,
    verifyEmailErrors,
  } = useSelector((state) => state.account);

  const {
    isConfirmVerificationCodeSuccess,
    confirmVerificationCodeErrorMessage,
  } = useSelector((state) => state.auth);

  const email = isForgotPassword
    ? JSON.parse(sessionStorage.getItem('forgotPasswordEmail'))['forgotPasswordEmail']
    : sessionStorage.getItem('currentVerificationEmail');
  const timerIntervalRef = useRef(null);
  const errorTimeOutRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(timerConfig.duration);
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);

  useEffect(() => {
    if (!email) {
      history.push('/login');
      return;
    }

    timerIntervalRef.current = setInterval(intervalCallback, timerConfig.timeout);
  }, []);

  useEffect(() => {
    if (isVerifyEmailSuccess) {
      history.push('/register');
    } else if (verifyEmailErrors.code) {
      runErrorTimeout();
      setLoading(false);
    }
  }, [isVerifyEmailSuccess, verifyEmailErrors]);

  useEffect(() => {
    if (isConfirmVerificationCodeSuccess) {
      history.push('/reset-password');
      sessionStorage.setItem('forgotPasswordEmail', JSON.stringify({
        ...JSON.parse(localStorage.getItem('forgotPasswordEmail')),
        verificationCode,
      }));
    } else if (confirmVerificationCodeErrorMessage) {
      runErrorTimeout();
      setLoading(false);
    }
  }, [isConfirmVerificationCodeSuccess, confirmVerificationCodeErrorMessage]);

  useEffect(() => {
    if (verificationCode.length === 6) {
      handleNextStep();
    }
  }, [verificationCode]);

  const runErrorTimeout = () => {
    setIsCodeInvalid(true);

    if (errorTimeOutRef.current) {
      clearTimeout(errorTimeOutRef.current);
    }

    errorTimeOutRef.current = setTimeout(() => setIsCodeInvalid(false), invalidCodeTimeout);
  };

  const validateCode = (value, isSubmit = false) => {
    if (isSubmit) {
      return verificationCode.length === validCodeLength;
    }

    if (value && value.length) {
      return value.length <= validCodeLength;
    }

    return !isSubmit;
  };

  const resendCode = () => {
    dispatch((isForgotPassword ? resendVerificationCodeRequest : sendEmailRequest)({
      email,
      lang: i18n.language,
      userAgent: '',
    }));

    setTimer(timerConfig.duration);
    timerIntervalRef.current = setInterval(intervalCallback, timerConfig.timeout);
  };

  const inputOnChange = (e) => {
    const { value } = e.target;

    if (!inputDigits.includes(value.substring(value.length - 1, value.length))) return;

    if (validateCode(value)) {
      setVerificationCode(value);
    }
  };

  const intervalCallback = () => {
    setTimer((t) => {
      if (t > 0) {
        return t - 1;
      }

      clearInterval(timerIntervalRef.current);
      return t;
    });
  };

  const handleNextStep = () => {
    if (!validateCode(undefined, true)) {
      runErrorTimeout();
      return;
    }

    setLoading(true);
    dispatch(
      (isForgotPassword ? confirmVerificationCodeRequest : verifyEmailRequest)({
        email,
        code: verificationCode,
        lang: i18n.language,
      }),
    );
  };

  const errorCode = isForgotPassword ? confirmVerificationCodeErrorMessage : verifyEmailErrors.code;

  return (
    <div className={!isForgotPassword ? 'auth page-base' : ''}>
      {!isForgotPassword ? (
        <AppBar color="transparent" position="static" elevation={0} className="login-header">
          <Toolbar>
            <Box mx="auto" className="auth-logo">
              <Link to="/login">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
      ) : null}
      <Container>
        <div className="form-container">
          <Grid container justifyContent="center">
            <Grid item xs={!isForgotPassword ? 4 : 8}>
              <Box className="auth-box form-box" p={4}>
                <Box mb="30px">
                  <p className="title">
                    {i18n.t('VerificationCode')}
                  </p>
                  <p className="sub-title">
                    {i18n.t('EnterVerificationCode')}
                    <br />
                    {`"${email}"`}
                    {formatTime(timer)}
                  </p>
                </Box>

                <Box textAlign="center" mb={3}>
                  <input
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    style={{
                      fontSize: '64px',
                      fontFamily: 'monospace',
                      width: '80%',
                      letterSpacing: '0.1em',
                      padding: '4px 0',
                      border: 'none',
                      borderBottom: `3px solid ${!isCodeInvalid ? '#2196F3' : 'red'}`,
                      outline: 'none',
                      textAlign: 'center',
                    }}
                    type="text"
                    onChange={inputOnChange}
                    value={verificationCode}
                  />

                  {isCodeInvalid ? (
                    <Typography color="error">
                      {errorCode || (!loading ? i18n.t('CodeInvalidError') : null)}
                    </Typography>
                  ) : null}
                </Box>

                <Box mb={2}>
                  <Button disabled={loading} fullWidth onClick={handleNextStep} variant="contained" color="primary">
                    <Link style={{ color: 'white' }} to="#">
                      {loading && <CircularProgress color="white" size={20} />}
                      {!loading && i18n.t('Next')}
                    </Link>
                  </Button>
                </Box>

                {timer === 0 ? (
                  <Typography align="center" color="secondary">
                    {`${i18n.t('DontGetCode')} `}
                    <Typography
                      component="span"
                      style={{ cursor: 'pointer' }}
                      color="primary"
                      onClick={resendCode}
                    >
                      {i18n.t('ResendCode')}
                    </Typography>
                  </Typography>
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}

EmailVerifyV2.propTypes = {
  // eslint-disable-next-line react/require-default-props
  isForgotPassword: PropTypes.bool,
};

export default EmailVerifyV2;
